<template>
  <div class="teacher">
    <list-template
        :current-page="page"
        :loading="loading"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        hasAdd
        @onAdd="onAdd"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        @onReset="search = null"
        @onSearch="onSearch"
    ></list-template>
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogVisible" width="500rem">
      <el-form>
        <el-form-item label="所属校区">
          <el-select v-if="title==='新增'" v-model="data.school_id" placeholder="请选择校区">
            <el-option v-for="item in searchConfig[0].options " :key="item.id" :label="item.school_name" :value="item.id"></el-option>
          </el-select>
          <span v-else>{{ data.school }}</span>
        </el-form-item>
        <el-form-item label="组别名称">
          <el-input v-model="data.name" maxlength="10" placeholder="请输入组别名称"></el-input>
        </el-form-item>
        <div style="margin-top: 128rem">
          <el-button :loading="butLoading" type="primary" @click="onConfirm">保存</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
// import TableTemplate from "@/components/TableTemplate.vue";

export default {
  // components: {TableTemplate},
  _config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      butLoading: false,
      searchConfig: [
        {
          value: 'id',
          options: [],
          tag: 'select',
          prop: 'school_id',
          label: 'school_name',
          placeholder: '请选择校区',
        },
      ],
      tableConfig: [
        {
          prop: 'name',
          label: '分组名称'
        },
        {
          prop: 'school',
          label: '所属校区'
        },
        {
          prop: 'creator',
          label: '创建人'
        },
        {
          prop: 'updated_at',
          label: '最新修改时间'
        },
        {
          prop: 'created_at',
          label: '创建时间'
        },
        {
          label: '操作',
          handle: true,
          width: 120,
          render: () => ['编辑']
        }
      ],
      search: null,
      total: 0,
      tableData: [],
      dialogVisible: false,
      title: '',
      data: {},
    }
  },
  computed: {
    ...mapState(['page'])
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  activated() {
    this.getData()
  },
  methods: {
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    getData() {
      let {search, page} = this;
      this.loading = true;
      this.$_axios2.get('api/attendance/group/list', {params: {...search, page}}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(() => this.loading = false);
    },
    onAdd() {
      this.data = {};
      this.title = '新增';
      this.dialogVisible = true;
    },
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    tableHandle(row, handle, index) {
      this.title = '编辑';
      this.dialogVisible = true;
      this.data = JSON.parse(JSON.stringify(row));
    },
    onConfirm() {
      if (this.title === '新增') {
        if (!this.data.school_id)
          return this.$message.error('所属校区不能为空');
        if (!this.data.name)
          return this.$message.error('组别名称不能为空');
        this.butLoading = true;
        this.$_axios2.post('api/attendance/group/store', this.data).then((res) => {
          if (res.data.status !== 0) return
          this.butLoading = false;
          this.$store.commit('setPage', 1)
          this.getData();
          this.dialogVisible = false;
          this.$message.success('新增成功');
        })
      } else {
        if (!this.data.name)
          return this.$message.error('组别名称不能为空');
        this.butLoading = true;
        this.$_axios2.post('/api/attendance/group/update', this.data).then((res) => {
          if (res.data.status !== 0) return
          this.butLoading = false;
          this.getData();
          this.dialogVisible = false;
          this.$message.success('修改成功');
        })
      }
    }
  },
  created() {
    this.$_axios.get('site/school', {params: {}}).then(res => {
      this.searchConfig[0].options = res.data.data;
    })
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }
}
</style>
